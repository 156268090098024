<template>
  <b-card class="pl-1 pt-1">
    <b-row>
      <b-col lg="6" md="8">
        <h5 class="bold">Terms and Conditions</h5>
        <p>
          Lorem ipsum, dolor sit aentium! Quas alias atque aperiam veniam. Qui
          alias laudantium aliquam sint doloribus facilis nihil corrupti sed,
          error similique, ipsam, tempora voluptatem consequuntur?
        </p>

        <p>
          Lorem ipsum, dolor sit aentium! Quas alias atque aperiam veniam. Qui
          alias laudantium aliquam sint doloribus facilis nihil corrupti sed,
          error similique, ipsam, tempora voluptate
        </p>

        <h5 class="bold">Conditions @</h5>

        <p>
          Lorem ipsum, dolor sit aentium! Quas alias atque aperiam veniam. Qui
          alias laudantium aliquam sint doloribus facilis nihil corrupti sed,
          error similique, ipsam, tempora voluptatem consequuntur?
        </p>

        <p>
          Lorem ipsum, dolor sit aentium! Quas alias atque aperiam veniam. Qui
          alias laudantium aliquam sint doloribus facilis nihil corrupti sed,
          error similique, ipsam, tempora voluptate
        </p>
        <b-button variant="outline-primary"> Sign an Accept </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
};
</script>
