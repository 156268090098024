<template>
  <b-card class="m-0">
    <b-row class="mx-1">
      <b-col lg="12" class="py-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar size="lg" src="../../assets/images/avatars/1-small.png" />
          </div>
          <h4 class="my-auto text-secondary">Generic Name</h4>
        </div>
        <div class="my-1">
          <h5 class="bold">Assaigment Title</h5>
          <p>Assignment ID</p>
        </div>
        <div>
          <h5>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum eius
            facelique! Eveniet dicta odio ducimus sint? Dolores, totam.
            Doloribus itaque possimus enim culpa.
          </h5>

          <h5>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facere
            beidem nobis dignissimos eius obcaecati aliquid? Dolorem
            reprehenderit ipsa veritatis at, praesentium velit impedit,
            nesciunt, reiciendis aut tempora quisquam nobis molestias corrupti
            est corporis!
          </h5>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BAvatar, BCard, BCol, BRow } from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
    BCard,
    BCol,
    BRow,
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
</style>
