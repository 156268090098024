<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        md="12"
        sm="12"
      >
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5 class="bold d-inline">
                Interview Confirmed
              </h5>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-col
          lg="4"
          md="6"
          sm="12"
        >
          <b-card
            no-body
            class="card-apply-job"
          >
            <b-card-header class="">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    size="42"
                    :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
                  />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="my-auto">Brain Quest Dubai</h3>
                  </h4></b-media-body>
              </b-media>
            </b-card-header>
            <b-card-body>
              <b-media
                no-body
                class=""
              >
                <b-media-aside class="mr-1">
                  <b-media-aside class="mr-1">
                    <b-avatar
                      rounded
                      variant="light-primary"
                      size="34"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        size="18"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class=" date">
                    <h6 class="mb-0 ">
                      Sat, May 25, 2020
                    </h6>
                    <small> 10:AM to 6:00 PM</small>
                  </b-media-body>
                </b-media-aside></b-media>
              <div class="mt-2  ">
                <h5>Interview Link</h5>
                <a href="">www.democompany.com</a>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="12"
        md="12"
        sm="12"
      >
        <b-card>
          <div
            class="d-lg-flex d-md-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar
                  size="lg"
                  src="../../assets/images/avatars/1-small.png"
                />
              </div>
              <div>
                <p class="my-0">
                  Assignment Manager
                </p>
                <h5 class="d-inline">
                  Generic Name
                </h5>
                <span> is available to solve your queries</span>
              </div>
            </div>
            <div class="mt-lg-0 mt-md-0 mt-1">
              <b-button variant="outline-primary">
                Have a Conversation
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="12"
        class="pt-2 mt-0"
      >
        <!-- assignment title component -->
        <AssignmentTitle />
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="12"
        class="pt-2 mt-0"
      >
        <!-- assignment title component -->
        <TermsAndCondition />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
BAvatar,
BBadge,
BButton,
BButtonGroup,
BCard,
BCardBody,
BCardHeader,
BCardText,
BCol,
BFormDatepicker,
BFormGroup,
BFormInput,
BFormTimepicker,
BImg, BInputGroupAppend,
BMedia,
BMediaAside,
BMediaBody, BRow
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import AssignmentTitle from './AssignmentTitle.vue'
import TermsAndCondition from './TermsAndCondition.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BImg,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormDatepicker,
    BInputGroupAppend,
    BCardText,
    BFormInput,
    BCardBody,
    BButtonGroup,
    BFormGroup,
    BRow,
    BCol,
    BFormTimepicker,
    flatPickr,
    TermsAndCondition,
    AssignmentTitle,
  },
  directives: {
    Ripple,
  },

  data() {
    return {

    }
  },
}
</script>
  <style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
